import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import SIGN_IN_MUTATION from '../../queries/signIn.graphql';
import GET_USER_DETAILS from '../../queries/getCustomer.graphql';
import { useSignIn } from '../../talons/SignIn/useSignIn';
import RenderMessages from '../../components/RenderMessages';
import Field from '../../components/Field';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { initializeApollo } from '../../lib/apollo-client';
import { Form } from 'informed';
import UserIcon from '../../public/static/icons/user_field.svg';
import PassIcon from '../../public/static/icons/pass_icon.svg';
import Link from 'next/link';
import GET_PRODUCTS_BY_SKU from '../../queries/getProductsBySku.graphql';
import APP_QUERY from '~/queries/App.graphql';
import { useStore } from 'react-redux';
import { useRouter } from 'next/router';
import { redirectTo } from '../../util/redirect';
import Breadcrumbs from '../../components/Breadcrumbs';
import {
    validateEmail,
    isRequired,
    validatePassword,
    hasLengthAtLeast
} from '../../util/formValidators';
import combine from '../../util/combineValidators';
import { LocalOverlay } from '../../components/Overlay';
import { NextSeo } from 'next-seo';

type preparedItemType = { quantity: number; sku: string };

const Login = () => {
    const userIcon = <UserIcon />;
    const passIcon = <PassIcon />;

    const store = useStore();
    const storeState = store.getState();
    const router = useRouter();
    const cartId = storeState.cart.cartId;
    const isSignedIn = storeState.user.isSignedIn;
    const [guestCartId, setGuestCartId] = useState(null);
    const guestWishlistLS = localStorage.getItem('guestWishlist');
    const preparedItems: preparedItemType[] = [];
    const existingGuestWishlist: string[] | null = guestWishlistLS
        ? JSON.parse(guestWishlistLS)
        : [];

    useEffect(() => {
        !isSignedIn && cartId ? setGuestCartId(cartId) : null;
    }, [cartId, isSignedIn]);

    useEffect(() => {
        if (isSignedIn) {
            redirectTo(router, '/account/overview');
        }
    }, []); //eslint-disable-line

    const [getProductsForWishlist, { data }] = useLazyQuery(
        GET_PRODUCTS_BY_SKU,
        {
            variables: {
                products: existingGuestWishlist
            }
        }
    );

    data?.products?.items.map((item: { sku: string }) => {
        const itemToPush = {
            quantity: 1,
            sku: item.sku
        };
        preparedItems.push(itemToPush);
    });

    const talons = useSignIn({
        signInMutation: SIGN_IN_MUTATION,
        getUserDetailsQuery: GET_USER_DETAILS,
        preparedItems,
        guestCartId
    });
    const {
        handleSubmit,
        signInData,
        errorUserDetails,
        signInError,
        signInLoading
    } = talons;

    const renderErrorMessage = () => {
        if (
            signInError?.message ==
            'The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.'
        ) {
            return 'Die Zugangsdaten sind nicht korrekt oder dein Konto ist vorübergehend nicht erreichbar. Bitte versuche es erneut.';
        }

        return 'Ein Fehler ist aufgetreten. Versuchen Sie es später erneut.';
    };

    return (
        <div className="w-full relative">
            <NextSeo title="Anmelden | Funkelhaus.de" />
            <div className="color-grid p-0">
                <Breadcrumbs current={'Anmelden'} />
            </div>

            <div className="flex flex-col items-center justify-center p-6">
                <>
                    <div className="flex flex-col w-full justify-center items-center lg:flex-row lg:items-stretch">
                        <div className="flex flex-col w-full max-w-md">
                            <div>
                                <h2 className="text-3xl font-semibold text-gray-700 text-center">
                                    Ich habe ein Kundenkonto bei Funkelhaus
                                </h2>
                            </div>
                            <Form onSubmit={handleSubmit}>
                                <Field>
                                    <TextInput
                                        id="email_login"
                                        field="email"
                                        type="email"
                                        isRequiredLabel={true}
                                        required
                                        validateOnBlur={true}
                                        validate={combine([
                                            isRequired,
                                            validateEmail
                                        ])}
                                        icon={userIcon}
                                        label="E-Mail Adresse"
                                    />
                                </Field>
                                <Field>
                                    <TextInput
                                        id="password"
                                        field="password"
                                        type="password"
                                        isRequiredLabel={true}
                                        required
                                        validateOnBlur={true}
                                        validate={combine([
                                            isRequired,
                                            [hasLengthAtLeast, 8],
                                            validatePassword
                                        ])}
                                        label="Passwort"
                                        icon={passIcon}
                                    />
                                </Field>
                                <Link href="/forgotten_password">
                                    <a
                                        className={
                                            'flex items-start my-3 underline'
                                        }
                                    >
                                        Passwort vergessen?
                                    </a>
                                </Link>
                                <Button
                                    className="w-full"
                                    onClick={() =>
                                        existingGuestWishlist &&
                                        existingGuestWishlist.length > 0
                                            ? getProductsForWishlist()
                                            : null
                                    }
                                >
                                    Anmelden
                                </Button>
                            </Form>
                            {signInError || errorUserDetails ? (
                                <RenderMessages
                                    message={renderErrorMessage()}
                                    messageStatus="error"
                                    isBox={true}
                                />
                            ) : null}
                        </div>
                        <div className="h-px w-full my-12 bg-gray-200 lg:hidden"></div>
                        <div className="hidden w-px mx-24 bg-gray-200 lg:block"></div>
                        <div className="flex flex-col items-center w-full max-w-md lg:self-start">
                            <div>
                                <h2 className="text-3xl font-semibold text-gray-700 text-center">
                                    Neu bei Funkelhaus
                                </h2>
                            </div>
                            <Link passHref href="/register">
                                <Button isNextLink={true} className="w-full">
                                    Weiter
                                </Button>
                            </Link>
                            <ul className="styled-list mt-4">
                                <li className="mb-4 text-lg text-black">
                                    Lieferung direkt vom Hersteller
                                </li>
                                <li className="mb-4 text-lg text-black">
                                    Klimaneutraler Versand
                                </li>
                                <li className="mb-4 text-lg text-black">
                                    30 Tage Geld-zurück-Garantie
                                </li>
                                <li className="text-lg text-black">
                                    Versandkostenfrei ab 50,- €
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Link passHref href="/">
                        <a className="mt-3 underline cursor-pointer lg:mt-6">
                            Zurück zum Shop
                        </a>
                    </Link>
                </>
            </div>
            {signInLoading || signInData ? (
                <LocalOverlay>
                    <span className="text-2xl text-black font-bold">
                        Wird bearbeitet…
                    </span>
                </LocalOverlay>
            ) : null}
        </div>
    );
};

export async function getStaticProps() {
    const apolloClient = initializeApollo();
    await apolloClient.query({
        query: APP_QUERY,
        fetchPolicy: 'network-only'
    }); // Preload App Data and navigation

    return {
        props: {
            initialApolloState: apolloClient.cache.extract() // load cached data from queries above into the initial state of the app
        },
        revalidate: Number(process.env.STATIC_PROPS_REVALIDATE)
    };
}

export default Login;
