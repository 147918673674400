import React, { Fragment, useRef, useState } from 'react';
import { BasicText, asField, FieldApi, FieldState } from 'informed';
import { compose } from 'redux';
import { Message } from '../Field';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';

interface TextInputProps {
    id: string;
    field: string;
    type: string;
    isRequiredLabel?: boolean;
    additionalclasses?: string;
    fieldApi?: FieldApi;
    fieldState?: FieldState<string>;
    icon?: JSX.Element;
    disabled?: boolean;
    label?: string;
    required?: boolean;
    rounded?: boolean;
    marginBottom?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

const TextInput = (props: TextInputProps) => {
    const {
        id,
        field,
        type,
        isRequiredLabel,
        required,
        additionalclasses,
        fieldState,
        fieldApi,
        icon,
        disabled,
        label,
        rounded,
        marginBottom,
        onChange
    } = props;

    const errorInputStyle =
        fieldState && fieldState.error
            ? 'border border-solid border-skin-red'
            : '';

    const roundedIconStyle = icon
        ? 'text-sm relative w-full border border-skin-primary rounded-md placeholder-gray-400 focus:outline-none py-2 pr-2 pl-12 rounded-md '
        : 'text-sm relative w-full border border-skin-primary rounded-md placeholder-gray-400 focus:outline-none py-2 px-2 rounded-md ';
    const nonRoundedIconStyle = icon
        ? 'text-sm relative w-full border border-skin-primary rounded-none placeholder-gray-400 focus:outline-none py-2 pr-2 pl-12 '
        : 'text-sm relative w-full border border-skin-primary rounded-none placeholder-gray-400 focus:outline-none py-2 px-2 ';

    const defaultStyles = rounded ? roundedIconStyle : nonRoundedIconStyle;

    const requiredSymbol = isRequiredLabel ? (
        <span
            className={
                'flex-inline items-center font-medium tracking-wide text-skin-red text-xs mt-1 ml-1 top-[-2px] relative'
            }
        >
            *
        </span>
    ) : null;

    const [passwordVisible, setPasswordVisible] = useState(false);

    const passwordFieldRef = useRef<HTMLInputElement>(null);

    const fieldIsEmpty =
        fieldState?.maskedValue || fieldState?.value ? false : true;
    const labelIconStyles = icon ? 'left-12' : 'left-2';
    return fieldState && fieldApi ? (
        <Fragment>
            <div className={marginBottom ? marginBottom : `mb-8`}>
                <div className="relative autoFillTest">
                    {icon ? (
                        <div
                            className={
                                'absolute flex border border-transparent left-0 top-0 h-full w-10'
                            }
                        >
                            <div
                                className={
                                    'flex items-center justify-center z-10 bg-gray-100 text-gray-600 text-lg h-full w-full border border-solid border-skin-primary border-r-0 ' +
                                    errorInputStyle
                                }
                            >
                                {icon}
                            </div>
                        </div>
                    ) : null}

                    <BasicText
                        id={id}
                        field={field}
                        name={field}
                        type={type}
                        required={required}
                        className={
                            defaultStyles +
                            ' peer ' +
                            errorInputStyle +
                            ' ' +
                            additionalclasses
                        }
                        fieldState={fieldState}
                        fieldApi={fieldApi}
                        disabled={disabled}
                        forwardedRef={passwordFieldRef}
                        onChange={onChange}
                    />
                    <label
                        htmlFor={field}
                        className={
                            fieldIsEmpty
                                ? 'absolute top-1/2 -translate-y-1/2 peer-focus:translate-y-0 transition-all peer-placeholder-shown:top-2 peer-focus:-top-2 peer-focus:text-xs pointer-events-none bg-white px-2 text-gray-400' +
                                  ' ' +
                                  labelIconStyles
                                : 'absolute transition-all !-top-2 text-xs pointer-events-none bg-white px-2 text-gray-400' +
                                  ' ' +
                                  labelIconStyles
                        }
                    >
                        {label}
                        {requiredSymbol}
                    </label>

                    {type == 'password' ? (
                        !passwordVisible ? (
                            <EyeIcon
                                className="absolute top-1/2 -translate-y-1/2 right-2 h-6 w-6 cursor-pointer"
                                onClick={() => {
                                    passwordFieldRef!.current!.type = 'text';
                                    setPasswordVisible(!passwordVisible);
                                }}
                            />
                        ) : (
                            <EyeOffIcon
                                className="absolute top-1/2 -translate-y-1/2 right-2 h-6 w-6 cursor-pointer"
                                onClick={() => {
                                    passwordFieldRef!.current!.type =
                                        'password';
                                    setPasswordVisible(!passwordVisible);
                                }}
                            />
                        )
                    ) : null}
                </div>
                {fieldState && fieldState.error && (
                    <Message fieldState={fieldState} />
                )}
            </div>
        </Fragment>
    ) : null;
};

export default compose(asField)(TextInput);
